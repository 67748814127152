// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Copyright',
  slug: '/copyright',
  abstract: 'Copyright',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Copyright', slug: '/copyright' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <div
      className="container"
      style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
    >
      <h1>Copyright</h1>
      <p>
        All texts are the copyright of the Sri Aurobindo Ashram Trust,
        Pondicherry, with the exception of the texts from the Mother’s Agenda.
        This compilation includes texts from:
      </p>
      <ul>
        <li>• The Mother on Auroville, First edition 1977</li>
        <li>
          • The Mother on Auroville, References in Mother’s Agenda Gilles Guigan
          (2018)
        </li>
        <li>
          • Auroville in Mother’s Words, Vol. I, II. Auroville Press Publishers,
          Auroville, 2018
        </li>
      </ul>
      <br />
      <p>
        <i>At Auroville nothing belongs to anyone in particular.</i>
        <br />
        <i>All is collective property.</i>
        <br />
        <i>To be utilised with my blessings for the welfare of all..</i>
      </p>
      <p style={{ textAlign: 'right' }}>- Mother, 14 May 1970</p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
